import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import BoardMember from "./BoardMember";
import RollOfHonor from "./RollOfHonor";
import OfficeBearerInfo from "./OfficeBearerInfo";
import Seo from "../../Seo";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

const board = [
    {
        "name": "Smita Chopra",
        "city": "Bangalore",
        "position": "President",
        "image": "https://assets.tangentindia.org/team/2024/smita-chopta.png"
    },
    {
        "name": "Lakshmi Siddharthan",
        "city": "Salem",
        "position": "Vice President",
        "image": "https://assets.tangentindia.org/team/2024/lakshmi-siddharthan.jpg"
    },
    {
        "name": "Archana Bhagat",
        "city": "Hyderabad",
        "position": "Secretary",
        "image": "https://assets.tangentindia.org/team/2024/archana-bhagat.jpg"
    },
    {
        "name": "Sonal Desai",
        "city": "Kolkata",
        "position": "Treasurer",
        "image": "https://assets.tangentindia.org/team/2024/sonal-desai.jpg"
    },
    {
        "name": "Nalini Prasad",
        "city": "Vellore",
        "position": "Imm Past President",
        "image": "https://assets.tangentindia.org/team/2024/nalini-prasad.jpg"
    },
    {
        "name": "Hema Yadavalli",
        "city": "Vizag",
        "position": "International Relations Officer",
        "image": "https://assets.tangentindia.org/team/2024/hema-yadavalli.jpg"
    },
    {
        "name": "Shanthi U",
        "city": "",
        "position": "Digital Convenor",
        "image": "https://assets.tangentindia.org/team/2024/shanthi-u.jpg"
    },
    {
        "name": "Kavita Khemka",
        "city": "",
        "position": "Tangent Business India TBNI Convenor",
        "image": "https://assets.tangentindia.org/team/2024/kavita-khemka.jpg"
    },
    {
        "name": "Puja Khullar",
        "city": "",
        "position": "NAGM Convenor",
        "image": "https://assets.tangentindia.org/team/2024/puja-khullar.jpg"
    },
    {
        "name": "Shalini Raheja",
        "city": "",
        "position": "MTM Convenor",
        "image": "https://assets.tangentindia.org/team/2024/shalini-raheja.jpg"
    },
    {
        "name": "Kanika Kataria",
        "city": "",
        "position": "Editor",
        "image": "https://assets.tangentindia.org/team/2024/kanika-kataria.jpg"
    },
    {
        "name": "Jagruthi Seth",
        "city": "",
        "position": "Regalia Convenor",
        "image": "https://assets.tangentindia.org/team/2024/jagruti-y-seth.jpg"
    },
    {
        "name": "Priya Srinivasu",
        "city": "",
        "position": "Corporate & Alliance Convenor",
        "image": "https://assets.tangentindia.org/team/2024/priya-srinivasu.jpg"
    },
    {
        "name": "Ranjana Singh",
        "city": "",
        "position": "International Fellowship Express Convenor",
        "image": "https://assets.tangentindia.org/team/2024/ranjana-singh.jpg"
    },
    {
        "name": "Amritha Kinger",
        "city": "",
        "position": "Project Convenor - Drishyam",
        "image": "https://assets.tangentindia.org/team/2024/amrita-kinger.jpg"
    },
    {
        "name": "Parul Kapoor",
        "city": "",
        "position": "Project Convenor - Drishyam Jyoti",
        "image": "https://assets.tangentindia.org/team/2024/parul-kapoor.jpg"
    },
    {
        "name": "Archana Dange",
        "city": "",
        "position": "Project Convenor - Crimson",
        "image": "https://assets.tangentindia.org/team/2024/archana-dange.jpg"
    },
    {
        "name": "Aarti Shroff",
        "city": "",
        "position": "Project Convenor - Crimson Plus",
        "image": "https://assets.tangentindia.org/team/2024/aarti-shroff.jpg"
    },
    {
        "name": "Shradha Khandelwal",
        "city": "",
        "position": "North Zone Officer",
        "image": "https://assets.tangentindia.org/team/2024/shradha-khandelwal.jpg"
    },
    {
        "name": "Ritu Singh",
        "city": "",
        "position": "North Fellowship Convenor",
        "image": "https://assets.tangentindia.org/team/2024/ritu-singh.jpg"
    },
    {
        "name": "Dr. Nagarekha Hebsur",
        "city": "",
        "position": "South Zone Officers (III & X)",
        "image": "https://assets.tangentindia.org/team/2024/nagarekha-hebsur.jpg"
    },
    {
        "name": "Shalaka Vora",
        "city": "",
        "position": "South Zone Officers (I, II & VIII)",
        "image": "https://assets.tangentindia.org/team/2024/shalaka-vora.jpg"
    },
    {
        "name": "Malavika Jain",
        "city": "",
        "position": "South Fellowship Convenor",
        "image": "https://assets.tangentindia.org/team/2024/malavika-jain.jpg"
    },
    {
        "name": "Deeti Nagarsheth",
        "city": "",
        "position": "West Zone Officer",
        "image": "https://assets.tangentindia.org/team/2024/deeti-nagarsheth.jpg"
    },
    {
        "name": "Kinjal Gala",
        "city": "",
        "position": "West Fellowship Convenor",
        "image": "https://assets.tangentindia.org/team/2024/kinjal-gala.jpg"
    },
    {
        "name": "Archana Swaika",
        "city": "",
        "position": "East Zone Officer",
        "image": "https://assets.tangentindia.org/team/2024/archana-swaika.jpg"
    },
    {
        "name": "Rachna Golyan",
        "city": "",
        "position": "East Fellowship Convenor",
        "image": "https://assets.tangentindia.org/team/2024/rachna-golyan.jpg"
    },
    {
        "name": "Bhupinder Chawla",
        "city": "",
        "position": "Performing Arts",
        "image": "https://assets.tangentindia.org/team/2024/bhupinder-chawla.jpg"
    },
    {
        "name": "Meenakshi Gupta",
        "city": "",
        "position": "Tangent Fine Art",
        "image": "https://assets.tangentindia.org/team/2024/meenakshi-gupta.jpg"
    },
    {
        "name": "Tania Gehani",
        "city": "",
        "position": "Tangent Fitness/Games",
        "image": "https://assets.tangentindia.org/team/2024/tania-gehani.jpg"
    },
    {
        "name": "Pawan Jain",
        "city": "",
        "position": "Book Club",
        "image": "https://assets.tangentindia.org/team/2024/pawan-jain.jpg"
    },
    {
        "name": "Swati Anurag",
        "city": "",
        "position": "Tangent Spiritual Arena",
        "image": "https://assets.tangentindia.org/team/2024/swati-anurag.jpg"
    }
];

// const officeBearers = [
//     {
//         "city": "Mumbai",
//         "club": 1,
//         "chairperson": "Paramjeet Kaur",
//         "secretary": "Minal Sandu"
//     },
//     {
//         "city": "Delhi",
//         "club": 2,
//         "chairperson": "Pawan Jain",
//         "secretary": "Archana Dixit"
//     },
//     {
//         "city": "Delhi",
//         "club": 3,
//         "chairperson": "Mamta Bhargava",
//         "secretary": "Reena Malhotra"
//     },
//     {
//         "city": "Hyderabad",
//         "club": 4,
//         "chairperson": "Savita Alla",
//         "secretary": "Archana Govindrajan "
//     },
//     {
//         "city": "Bangalore",
//         "club": 5,
//         "chairperson": "Nandita  Sanghani",
//         "secretary": "Era Goel"
//     },
//     {
//         "city": "Mumbai",
//         "club": 6,
//         "chairperson": "Priya Srinivasu",
//         "secretary": "Alpa Karkhanis"
//     },
//     {
//         "city": "Kolkata",
//         "club": 7,
//         "chairperson": "Priti Agarwal",
//         "secretary": "Swati Saraf"
//     },
//     {
//         "city": "Bhubaneswar",
//         "club": 8,
//         "chairperson": "Renu Gupta",
//         "secretary": "Jyoti Singh Deo"
//     },
//     {
//         "city": "Kolkata",
//         "club": 9,
//         "chairperson": "Archana Barma",
//         "secretary": "Archana Swaika"
//     },
//     {
//         "city": "Bangalore",
//         "club": 10,
//         "chairperson": "Sukanya Lakshmi Narayan",
//         "secretary": "Divya A. Hiranandani"
//     },
//     {
//         "city": "Nagpur",
//         "club": 11,
//         "chairperson": "Meenakshi Sial",
//         "secretary": "Divya Ladha"
//     },
//     {
//         "city": "Lucknow",
//         "club": 12,
//         "chairperson": "Meeta Rastogi",
//         "secretary": "Jyoti Dewan "
//     },
//     {
//         "city": "Bangalore",
//         "club": 14,
//         "chairperson": "Anuradha Vijayanagar",
//         "secretary": "Varkha Jeetendra Valecha"
//     },
//     {
//         "city": "Mumbai",
//         "club": 15,
//         "chairperson": "Veena Vijaynagar",
//         "secretary": "Sangeeta Ajwani"
//     },
//     {
//         "city": "Bhilwara",
//         "club": 16,
//         "chairperson": "Mridula Mansinghka",
//         "secretary": "Rashmi Garg"
//     },
//     {
//         "city": "Raipur",
//         "club": 17,
//         "chairperson": "Prena Gautam",
//         "secretary": "Shilpa sarda"
//     },
//     {
//         "city": "Bangalore",
//         "club": 18,
//         "chairperson": "Kajol Bhatia",
//         "secretary": "Hari Priya"
//     },
//     {
//         "city": "Bangalore",
//         "club": 19,
//         "chairperson": "Shamim Fazal",
//         "secretary": "Rachna Gupta"
//     },
//     {
//         "city": "Chennai",
//         "club": 20,
//         "chairperson": "Namita Maggon",
//         "secretary": "Jayanthi Vummidi"
//     },
//     {
//         "city": "Vadodra",
//         "club": 21,
//         "chairperson": "Kaushangi Dholikia",
//         "secretary": "Meena Kemkar"
//     },
//     {
//         "city": "Hubli",
//         "club": 22,
//         "chairperson": "Shantala S Shetty",
//         "secretary": "Gayathri Dhruva Desai"
//     },
//     {
//         "city": "Salem",
//         "club": 23,
//         "chairperson": "Dr. Manjoo Shree N",
//         "secretary": "Roopali Prabhu"
//     },
//     {
//         "city": "Vellore",
//         "club": 24,
//         "chairperson": "Shyamala Ravikumar",
//         "secretary": "Sreevalli Shanmugam"
//     },
//     {
//         "city": "Cuttak",
//         "club": 25,
//         "chairperson": "Payal Rathor",
//         "secretary": "Neelam Kandoi"
//     },
//     {
//         "city": "Pondicherry",
//         "club": 26,
//         "chairperson": "Vidyalakshmi C",
//         "secretary": "Manoja S"
//     },
//     {
//         "city": "Coimbatore",
//         "club": 27,
//         "chairperson": "Kalpana Ramesh",
//         "secretary": "Jayanthi Murthy"
//     },
//     {
//         "city": "Siligudi",
//         "club": 28,
//         "chairperson": "Samta Bhansali",
//         "secretary": "Ritu Poddar"
//     },
//     {
//         "city": "Hubli",
//         "club": 29,
//         "chairperson": "Noor Aisha Firdous Sadiq",
//         "secretary": "Neha M Pawar"
//     },
//     {
//         "city": "Hubli",
//         "club": 30,
//         "chairperson": "Neepa Mehta",
//         "secretary": "Divya Shetty"
//     },
//     {
//         "city": "Bangalore",
//         "club": 31,
//         "chairperson": "Latha Bhaskar",
//         "secretary": "Lis  Wills"
//     },
//     {
//         "city": "Surat",
//         "club": 32,
//         "chairperson": "Sangita Rathod",
//         "secretary": "Priti Jariwala"
//     },
//     {
//         "city": "Delhi",
//         "club": 33,
//         "chairperson": "Tina Mittal",
//         "secretary": "Renu Lal"
//     },
//     {
//         "city": "Vishakapatnam",
//         "club": 34,
//         "chairperson": "Reena Somani",
//         "secretary": "Anita Nuthakki"
//     },
//     {
//         "city": "Tirupur",
//         "club": 35,
//         "chairperson": "Pooja Alexander",
//         "secretary": "Kripaa Shetty"
//     },
//     {
//         "city": "Lucknow",
//         "club": 36,
//         "chairperson": "Aparna Narain",
//         "secretary": "Sakshi Jain"
//     },
//     {
//         "city": "Mumbai",
//         "club": 37,
//         "chairperson": "Meher Mirpuri",
//         "secretary": "Anisha Agarwal"
//     },
//     {
//         "city": "Belgaum",
//         "club": 38,
//         "chairperson": "Sangeeta Chandak",
//         "secretary": "Soniya Harpreet  Khurana"
//     },
//     {
//         "city": "Tumkur",
//         "club": 39,
//         "chairperson": "Manasi Prashanth",
//         "secretary": "Savitha Bharatheesh"
//     },
//     {
//         "city": "Bangalore",
//         "club": 40,
//         "chairperson": "Aarti Gupta",
//         "secretary": "Shilpi Shah"
//     },
//     {
//         "city": "Davangere",
//         "club": 41,
//         "chairperson": "Ashvini Thomas",
//         "secretary": "Vidya Niranjan"
//     },
//     {
//         "city": "Pondicherry",
//         "club": 42,
//         "chairperson": "Kala Ramakrishnan",
//         "secretary": "V Kalpana Jayaram"
//     },
//     {
//         "city": "Bangalore",
//         "club": 43,
//         "chairperson": "Rachna Malhotra",
//         "secretary": "Sonali Soni"
//     },
//     {
//         "city": "Kannur",
//         "club": 44,
//         "chairperson": "Shine Benaven",
//         "secretary": "Shalini George"
//     },
//     {
//         "city": "Ahmedabad",
//         "club": 45,
//         "chairperson": "Medha Mehrishi",
//         "secretary": "Shikha Bansal"
//     },
//     {
//         "city": "Pune",
//         "club": 46,
//         "chairperson": "Artavi Dedhia",
//         "secretary": "Sheetal Vijay Agarwal"
//     },
//     {
//         "city": "Coonoor",
//         "club": 47,
//         "chairperson": "Nayana Arjun",
//         "secretary": "Punita Dani"
//     },
//     {
//         "city": "Coimbatore",
//         "club": 48,
//         "chairperson": "Rachana Jaiin",
//         "secretary": "Nandini Premkumar"
//     },
//     {
//         "city": "Bangalore",
//         "club": 49,
//         "chairperson": "Bhavna Bajaj",
//         "secretary": "Ishrat Hussain"
//     },
//     {
//         "city": "Vellore",
//         "club": 50,
//         "chairperson": "Nanthini M",
//         "secretary": "Saritha Sathish"
//     },
//     {
//         "city": "Chennai",
//         "club": 51,
//         "chairperson": "Meenakshi Gupta",
//         "secretary": "Shanta Narayanan"
//     },
//     {
//         "city": "Bareilly",
//         "club": 52,
//         "chairperson": "Shradha Khandelwal",
//         "secretary": "Ruchi Jasoria"
//     },
//     {
//         "city": "Pilibhit",
//         "club": 53,
//         "chairperson": "Rashmi Goel",
//         "secretary": "Asha Singh"
//     },
//     {
//         "city": "Nagpur",
//         "club": 54,
//         "chairperson": "Manisha Verma",
//         "secretary": "Shweta Tapadiya"
//     },
//     {
//         "city": "Hubli",
//         "club": 55,
//         "chairperson": "Nandini Agadi",
//         "secretary": "Ranjita"
//     },
//     {
//         "city": "Vijayawada",
//         "club": 56,
//         "chairperson": "Sridevi Prasad",
//         "secretary": "Desu Lakshmi Suchitra Yogesh"
//     },
//     {
//         "city": "Hyderabad",
//         "club": 57,
//         "chairperson": "Sudha Sinha",
//         "secretary": "Rachna Agarwal"
//     }
// ]

export default class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            team: []
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        fetch('https://45ujsz9aog.execute-api.ap-south-1.amazonaws.com/meet-the-team')
            .then(resp => resp.json())
            .then(resp => resp.filter(item => item.clubNum !== "Amigo"))
            .then(resp => this.setState({
                ...this.state,
                team: resp
            }));
    }

    showModal() {
        this.setState({
            ...this.state,
            showModal: true
        })
    }

    hideModal() {
        this.setState({
            ...this.state,
            showModal: false
        })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/team/president-message.png')",
            backgroundSize: 'contain'
        };
        return (
            <div id="team">
                <Seo title='Meet the Tangent India Team | Tangent India'
                     description="Tangent India's Team comprises of a National Board and the Office Bearers of each Tangent India Club across the country."
                />
                {/*<div id="team-header-background" style={sectionStyle}  onClick={() => console.log('hi')}>*/}
                {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
                {/*         className="hidden header-foreground" alt=""/>*/}
                {/*</div>*/}
                {/*<div className={'text-center'} onClick={() => console.log('hah')}>*/}
                {/*    <img src='https://assets.tangentindia.org/assets/team/president-message.png'*/}
                {/*         className="text-center" width={'80%'} alt="" onClick={this.showModal}/>*/}
                {/*</div>*/}
                {/*<Modal show={this.state.showModal} onHide={this.hideModal} lg centered fullscreen>*/}
                {/*    <Modal.Header closeButton>*/}
                {/*        /!*<Modal.Title>Modal heading</Modal.Title>*!/*/}
                {/*    </Modal.Header>*/}
                {/*    <Modal.Body>*/}
                {/*        <img src='https://assets.tangentindia.org/assets/team/president-message.png' width={'100%'}/>*/}
                {/*    </Modal.Body>*/}
                {/*    <Modal.Footer>*/}
                {/*        /!*<Button variant="secondary" onClick={() => this.setState({...this.state, modal: false})}>*!/*/}
                {/*        /!*    Close*!/*/}
                {/*        /!*</Button>*!/*/}
                {/*        /!*<Button variant="primary" onClick={() => this.setState({...this.state, modal: false})}}>*!/*/}
                {/*        /!*    Save Changes*!/*/}
                {/*        /!*</Button>*!/*/}
                {/*    </Modal.Footer>*/}
                {/*</Modal>*/}

                <div className="title center">
                    <h1>
                        Meet the Tangent India Team
                    </h1>
                </div>
                <div className="section">
                    <Container>
                        <Row>
                            <h2>
                                Board Members 2024-25
                            </h2>
                            {board.length === 0 && <Row><h3 className={'text-center'}>Coming Soon!</h3></Row>}
                            <Row className="board-members">
                                {board.map((member, idx) => {
                                    return (
                                        <BoardMember
                                            key={idx}
                                            name={member.name}
                                            city={member.city}
                                            position={member.position}
                                            image={member.image}
                                        />
                                    );
                                })}
                            </Row>
                        </Row>
                    </Container>
                </div>
                <div className="section grey-bg">
                    <Container>
                        <Row>
                            <h2>
                                List of Office Bearers
                            </h2>
                            <Row className="board-members">
                                {this.state.team.map((club, idx) => {
                                    return (
                                        <OfficeBearerInfo
                                            key={idx}
                                            chairperson={club.chairperson}
                                            city={club.city}
                                            secretary={club.secretary}
                                            number={club.clubNum}
                                        />
                                    );
                                })}
                            </Row>
                        </Row>
                    </Container>
                </div>
                <div className="section">
                    <Container>
                        <Row>
                            <h2>
                                Roll of Honor
                            </h2>
                            <RollOfHonor/>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}


